<template>
  <div class="add">
    <!-- 新增量表 -->
    <div class="header">
      <template>
        <el-menu :default-active="$route.path" text-color="#555"  mode="horizontal" @select="handleSelect" :router="true">
          <el-menu-item :index="item.path" v-for="item in addMenuList" :key="item.id">{{ item.title }}</el-menu-item>
        </el-menu>

        <div class="content">
        <router-view></router-view>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      addMenuList: [{ id: 1, title: '计划概况', name: 'first', path: '/home/addPlan/PlanOverview' }, { id: 2, title: '参与用户', name: 'second',path:'/home/addPlan/ParticipatingUsers' }]
    };
  },
  methods: {
    // handleClick(tab, event) {
    //   console.log(tab.name);
    //   if (tab.name === 'first') {
    //     // this.activeName = 'first';
    //     this.$router.push('/home/addPlan/PlanOverview')
    //   } else {
    //     // this.activeName = 'second'
    //     this.$router.push('/home/addPlan/ParticipatingUsers')
    //   }
    // },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
    //   console.log(key, keyPath);
    }
  },
  created() {
    // console.log(this.$route.path)
    // if (this.$route.path === '/home/addGauge/scaleIntroduction') {
    //   this.activeName = 'first'
    // } else if (this.$route.path === '/home/addGauge/titleEditor') {
    //   this.activeName = 'second'
    // } else if (this.$route.path === '/home/addGauge/dimensionDivision') {
    //   this.activeName = 'third'
    // } else if (this.$route.path === '/home/addGauge/normalSetting') {
    //   this.activeName = 'fourth'
    // } else {
    //   this.activeName = 'fifth'
    // }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0 35px;
  padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu{
      position:sticky;
      top: 0;
      z-index: 2;
    }
    .el-menu--horizontal > .el-menu-item{
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  .content{
    padding: 20px 0px;
  }

}
</style>